var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  return function (e, t, n) {
    t.prototype.isYesterday = function () {
      var e = "YYYY-MM-DD",
        t = n().subtract(1, "day");
      return this.format(e) === t.format(e);
    };
  };
});
export default exports;